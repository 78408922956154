import axios from "axios";
import { EmailReceiptRequest } from "../../types/request/emailReceiptRequest.type";
import { getApiUrl, getClientApiUrl } from "../kioskApp.service";
import { getLocalStorageAccessToken } from "../localStorage.service";
import { handleResponse } from "./apiHelper";
import { setSignInError, setAccessToken } from "../../store/auth/auth.reducer";
import store from "../../store/store";

export const prepareReceipt = (receiptHtml: string) => {
    return axios.post(getApiUrl() + "/v1/kiosk/print/prepareReceipt", {
        Base64Content: receiptHtml
    }, {
        headers: {
            "Authorization": "Bearer " + getLocalStorageAccessToken()
        }
    }).catch((error) => {
            
        var errorMessage = "Unable to login to Kiosk. Please contact Xplor Recreation support for assistance";
        store.dispatch(setSignInError(errorMessage));
        store.dispatch(setAccessToken(null));
    }).then((response: any) => {
        const responseData = handleResponse<any>(response.data);
        return responseData;
    });
}

export const sendEmail = (receiptRequest: EmailReceiptRequest) => {
    return axios.post(getApiUrl() + "/v1/kiosk/attendUser/emailreceipt", receiptRequest, {
        headers: {
            "Authorization": "Bearer " + getLocalStorageAccessToken()
        }
    }).catch((error) => {
            
        var errorMessage = "Unable to login to Kiosk. Please contact Xplor Recreation support for assistance";
        store.dispatch(setSignInError(errorMessage));
        store.dispatch(setAccessToken(null));
    });
}

export const printReceipt = (data: any) => {
    return axios.post(getClientApiUrl() + "PrintJobs/DispatchPrintJob", data);
}