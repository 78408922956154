import axios from "axios";
import { MemberSignInModel, AdminSignInModel, ScannerMemberSignInModel } from "../types/auth/signIn.type"
import { handleResponse } from "./api/apiHelper";
import { getApiUrl } from "./kioskApp.service";

export const memberSignIn = (signInProps: MemberSignInModel) => {
    return axios.post(getApiUrl() + "/v1/user/token/kiosklogin", {
        kioskId: signInProps.kioskId,
        username: signInProps.userName,
        password: signInProps.password
    }).then((response: any) => {
        return response.data;
    });
}

export const adminSignIn = (signInProps: AdminSignInModel) => {
    return axios.post(getApiUrl() + "/kiosk/admin/login", {
        username: signInProps.userName,
        password: signInProps.password
    }).then((response: any) => {
        return handleResponse<any>(response.data);
    });
}

export const activateKioskByPinCode = (pinCode: string) => {
    return axios.post(getApiUrl() + "/kiosk/admin/activatekioskbypincode", {
        pinCode: pinCode
    }).then((response: any) => {
        return handleResponse<any>(response.data);
    });
}

export const scannerMemberSignIn = (signInProps: ScannerMemberSignInModel) => {
    return axios.post(getApiUrl() + "/v1/user/token/kioskscannerlogin", {
        scannerCode: signInProps.scannerCode.split('\r')[0],
        kioskId: signInProps.kioskId
    }).then((response: any) => {
        return response.data;
    });
}