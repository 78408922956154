import axios from "axios";
import { getApiUrl } from "../kioskApp.service";
import { handleResponse } from "./apiHelper";

export const getKioskSettings = (kioskId: string) => {
    return axios.get(getApiUrl() + "/v1/kiosk/settings/kiosksettings", {
        headers: {
            "KioskId": kioskId
        },
        params: {
            kioskId: kioskId
        }
    }).then((response: any) => {
        return handleResponse<any>(response.data);
    });
}