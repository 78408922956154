export const getApiUrl = () => {
    return process.env.REACT_APP_API_URL;
}

export const getClientApiUrl = () => {
    return process.env.REACT_APP_PTS_URL + "api/";
}

export const getScannerHubUrl = () => {
    return process.env.REACT_APP_PTS_URL + "PerfectMindService/SignalR/KioskScannerHub";
}