import axios from "axios";
import { ApiResponse } from "../../types/api/apiResponse.type";
import { getLocalStorageAccessToken } from "../localStorage.service";
import { setSignInError, setAccessToken } from "../../store/auth/auth.reducer";
import store from "../../store/store";

export const handleResponse = <T>(apiResponse: ApiResponse<T>) => {
    if (!apiResponse.isSuccess || apiResponse.errorMessage) {
        throw new Error(apiResponse.errorMessage);
    }
    return apiResponse.result;
}

export const getImage = (imageUrl: string) => {

    return axios.get(imageUrl, {
        headers: {
            "Authorization": "Bearer " + getLocalStorageAccessToken()
        },
        responseType: "blob"
    }).catch((error) => {
            
        var errorMessage = "Unable to login to Kiosk. Please contact Xplor Recreation support for assistance";
        store.dispatch(setSignInError(errorMessage));
        store.dispatch(setAccessToken(null));
    }).then((response: any) => {
        let blob = new Blob([response.data]);
        return blob;
    });
}