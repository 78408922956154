import axios from "axios";
import { format } from "date-fns";
import { EmailReceiptRequest } from "../../types/request/emailReceiptRequest.type";
import { getApiUrl } from "../kioskApp.service";
import { getLocalStorageAccessToken } from "../localStorage.service";
import { handleResponse } from "./apiHelper";
import { setSignInError, setAccessToken } from "../../store/auth/auth.reducer";
import store from "../../store/store";

export const getSessions = () => {

    return axios.get(getApiUrl() + "/v1/kiosk/attendUser/sessions", {
        headers: {
            "Authorization": "Bearer " + getLocalStorageAccessToken()
        }
        }).catch((error) => {
            
            var errorMessage = "Unable to login to Kiosk. Please contact Xplor Recreation support for assistance";
            store.dispatch(setSignInError(errorMessage));
            store.dispatch(setAccessToken(null));
        }).then((response: any) => {

            const responseData = handleResponse<any>(response.data);
            return responseData.yourClasses.map((item: any) => {
                var startTime = format(new Date(item.startTime), "hh:mm a");
                return {
                    id: item.id,
                    title: item.title,
                    startTime: item.startTime,
                    startTimeFormat: startTime + " (" + item.durationInMins + " mins)",
                    rank: item.rank,
                    facilityName: item.facility,
                    locationName: item.location,
                    instructor: item.instructor,
                    spotsLeft: "4 spots left",
                    startTimeUtc: item.startTimeUtc
                }
            });
        });
}

export const attendUser = (classItem: any) => {
    return axios.post(getApiUrl() + "/v1/kiosk/attendUser/attend", {
        EventId: classItem.id,
        SessionStartUtc: classItem.startTimeUtc
    }, {
        headers: {
            "Authorization": "Bearer " + getLocalStorageAccessToken()
        }
    }).catch((error) => {
            
        var errorMessage = "Unable to login to Kiosk. Please contact Xplor Recreation support for assistance";
        store.dispatch(setSignInError(errorMessage));
        store.dispatch(setAccessToken(null));
    });
}

export const sendEmail = (receiptRequest: EmailReceiptRequest) => {
    return axios.post(getApiUrl() + "/v1/kiosk/attendUser/emailreceipt", receiptRequest, {
        headers: {
            "Authorization": "Bearer " + getLocalStorageAccessToken()
        }
    }).catch((error) => {
            
        var errorMessage = "Unable to login to Kiosk. Please contact Xplor Recreation support for assistance";
        store.dispatch(setSignInError(errorMessage));
        store.dispatch(setAccessToken(null));
    });
}