import { HubConnectionBuilder, LogLevel } from "@microsoft/signalr";
import { ScannerSettings } from "../types/settings/scannerSettings.type";
import { getScannerHubUrl } from "./kioskApp.service";

export const createScannerHubConnection = () => {
    return new HubConnectionBuilder()
        .withUrl(getScannerHubUrl())
        .withAutomaticReconnect()
        .configureLogging(LogLevel.Debug)
        .build()
}

export const initializeScanner = (hubConnection: any, scannerSettings: ScannerSettings) => {

    hubConnection.onclose((e: any) => {
        console.warn("Scanner Hub connection was closed. Error: " + e.toString())
    });

    hubConnection.onreconnecting((e: any) => {
        console.warn("Scanner Hub reconnecting. Error: " + e.toString())
    });

    return hubConnection.start()
        .then(() => {

            return hubConnection.invoke("InitializeScanner", {
                ComPort: scannerSettings.comPort,
                BaudRate: scannerSettings.baudRate,
                DataBits: scannerSettings.dataBits
            });
        });
}